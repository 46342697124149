import React from 'react';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import { ThemeType } from '../theme/Theme';

// eslint-disable-next-line
enum FontSize {
  s = '0.75rem',
  m = '1rem',
  xm = '1.25rem',
  l = '1.5rem',
  xl = '1.75rem',
  xxl = '2rem',
  xxxl = '2.5rem',
  _8xl = '3.5rem',
}
// eslint-disable-next-line
enum Alignment {
  start = 'start',
  center = 'center',
  end = 'end',
}

export interface ParagraphProps {
  fontSize?: keyof typeof FontSize;
  children?: React.ReactNode;
  color?: keyof ThemeType;
  align?: keyof typeof Alignment;
  textDecoration?: React.CSSProperties['textDecoration'];
  fontWeight?: React.CSSProperties['fontWeight'];
  cutText?: boolean;
  style?: React.CSSProperties;
  wordBreak?: React.CSSProperties['wordBreak'];
  direction?: React.CSSProperties['direction'];
  dangerouslySetInnerHTML?: {
    __html: string;
  };
}

const P = styled.p<ParagraphProps>`
  font-size: ${(props) => (props.fontSize ? FontSize[props.fontSize] : '1rem')};
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme['textColor']};
  text-align: ${(props) => (props.align ? Alignment[props.align] : 'start')};
  text-decoration: ${({ textDecoration }) => textDecoration};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-overflow: ${(props) => (props.cutText ? 'ellipsis' : 'auto')};
  overflow: ${(props) => (props.cutText ? 'hidden' : 'visible')};
  word-break: ${(props) => props.wordBreak};
  white-space: ${(props) => (props.cutText ? 'nowrap' : 'normal')};
  direction: ${({ direction }) => direction || 'inherit'};
`;

export const Text: React.FC<ParagraphProps> = (props) => {
  const { theme } = useTheme();
  return <P {...props} theme={theme}></P>;
};
