import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { UserRoles } from '../../model/user/types/UserRoles';
import { permissionActions } from '../../redux/permission/permission.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { PermissionOrganizationMemberTab } from './tabs/PermissionOrganizationMember';
import { PermissionsInvitesTab } from './tabs/PermissionsInvitesTab';
import { PermissionsMembersTab } from './tabs/PermissionsMembersTab';

enum TabKeys {
  MEMBERS = 'MEMBERS',
  INVITES = 'INVITES',
  NON_ORGANIZATION = 'NON_ORGANIZATION',
}

export const Permissions: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;

  const onTabChange = (key: string) => {
    switch (key as TabKeys) {
      case TabKeys.MEMBERS:
        dispatch(permissionActions.getMembers({ userId: user._id }));
        break;
      case TabKeys.INVITES:
        dispatch(permissionActions.getInvitedMembers({ userId: user._id }));
        break;
    }
  };

  return (
    <Flex flexDirection="column">
      <SubHeader
        title={t('header.dropdown.permissions')}
        buttonTitle={t('permission.add_member')}
        icon={faPlus}
        enabled={false}
        showButton={false}
      />
      <AtiraTabs
        onChange={onTabChange}
        items={
          user.roles.includes(UserRoles.MEMBER)
            ? [
                {
                  key: TabKeys.NON_ORGANIZATION,
                  label: t('common.organization'),
                  children: <PermissionOrganizationMemberTab />,
                },
              ]
            : [
                {
                  key: TabKeys.MEMBERS,
                  label: t('permissions.members'),
                  children: <PermissionsMembersTab />,
                },
                {
                  key: TabKeys.INVITES,
                  label: t('permissions.invites'),
                  children: <PermissionsInvitesTab />,
                },
              ]
        }
      />
    </Flex>
  );
};
